import store from "@/_evoli/store"
import goaesHeaders from "@/aquana/helper/container/goaesHeaders"
import goaesLinks from "@/aquana/helper/container/goaesLinks"

export default [
  {
    path: "goaes",
    name: "Gebührenprüfungen",
    meta: {
      authorize: true,
      expert: "takesGoae",
      entityName: "goaes",
      lists: [
        {
          name: "goaesList",
          entityName: "goaes",
          getHeaders: () => goaesHeaders(store),
          getLinks: () => goaesLinks(store, "goaesList")
        }
      ]
    },
    component: () =>
      import(
        /* webpackChunkName: "goaes" */ "@/aquana/views/goaes/GoaeContainer.vue"
      ),
  },
  {
    path: "goaes/:id",
    name: "Gebührenprüfung",
    meta: {
      authorize: true,
      expert: "takesGoae",
      entityName: "goaes",
    },
    component: () =>
      import(
        /* webpackChunkName: "goaes" */ "@/aquana/views/goaes/GoaeForm.vue"
      ),
  },
];
