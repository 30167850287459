import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/_evoli/store";
import permission from "@/_evoli/helper/permission";

let children, redirect;
try {
  if(process.env.VUE_APP_PORTAL === "aquana"){
    children = require('@/aquana/router/index.js').default;
    redirect = true
  }
  if(process.env.VUE_APP_PORTAL === "flamara"){
    children = require('@/flamara/router/index.js').default;
  }
  if(process.env.VUE_APP_PORTAL === "blitza"){
    children = require('@/blitza/router/index.js').default;
  }
} catch (e) {
  console.error(e);
}

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Portal",
    children,
    meta: {
      authorize: true,
      redirect
    },
    component: () => import(/* webpackChunkName: "portal" */ "@/" + process.env.VUE_APP_PORTAL + "/views/Index.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      authorize: false,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/_evoli/views/Login.vue"),
  },
  {
    path: "/passwordreset/:token",
    name: "Passwort zurücksetzen",
    meta: {
      authorize: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "passwordreset" */ "@/_evoli/views/PasswordReset.vue"
      ),
  },
  {
    path: "/unavailable",
    name: "Unavailable",
    meta: {
      authorize: false,
    },
    component: () =>
      import(/* webpackChunkName: "portal" */ "@/_evoli/views/ServiceNotAvailable.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    meta: {
      authorize: true,
    },
    component: () =>
      import(/* webpackChunkName: "portal" */ "@/_evoli/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//redirect if already logged in
router.beforeEach((to, from, next) => {
  if(to.name === "Login" && store.state.auth.refreshToken){
    return next("/")
  }
  next()
})

//change title
router.beforeEach((to, from, next) => {
  document.title = process.env.VUE_APP_TITLE
  next()
})

//clear after not new
router.afterEach((to, from) => {
  if (!from.path.includes("new")){
    store.commit("form/clear");
  }
})

//check changedValues
router.beforeEach((to, from, next) => {
  let changed = false
  Object.keys(store.state.form.changedValues).forEach(key => {
    if(store.state.form.changedValues[key].length > 0){
      console.error(JSON.stringify(store.state.form.changedValues))
      changed = true
    }
  });
  if(changed){
    store.commit("snackbar/open", {
      content: "Wenn Sie die Seite verlassen, gehen nicht gespeicherte Änderungen verloren.\nMöchten Sie dennoch fortfahren?",
      buttonLabel: "Ja",
      closeLabel: "Nein",
      color: "error",
      callback: (value) => {
        if (value) {
          store.commit("form/clearAllChangedValues")
          next()
        }
      },
    });
  } else {
    store.commit("form/clearAllChangedValues")
    next()
  }

})

//check authorize and permission
router.beforeEach((to, from, next) => {
  if(to.path === "/unavailable"){
    return next()
  }
  if (to.meta.authorize && !store.state.auth.refreshToken)
    return next(process.env.VUE_APP_UNAUTHORIZE_LANDING);
  if (!store.state.auth.self?.roles && store.state.auth.refreshToken) {
    store.dispatch("self").then(() => {
      checkPermssion(to, from, next)
    })
    .catch((error) => {
      if(error.message === "Failed to fetch"){
        next("/unavailable");
      } else {
        store.dispatch("logout").then(() => {
          next("/");
        })
      }
    })
  } else {
    checkPermssion(to, from, next)
  }
});

//remove search
router.beforeEach((to, from, next) => {
  if(from.meta.lists && from.meta.lists.length > 0){
    from.meta.lists.forEach(list => {
      if(!store.getters.checkDeleteSearch(list)){
        const filterList = store.getters.getFilterList(list)
        store.commit("setFilterList", {
          key: list.name,
          values: {
            ...filterList,
            search: null,
          },
        });
      }
    })
  }
  next()
})

//socket
router.beforeEach((to, from, next) => {
  store.dispatch("socket/SEND_route", to)
  
  let filter = {}
  if(to.meta?.lists && to.meta.lists.length < 2){
    filter = store.state.filterList[to.meta.lists[0].name] || {}
  }
  Object.keys(filter).map((key) => {
    if (!filter[key] || filter[key] === "") delete filter[key];
  });

  store.dispatch("socket/SEND_lists", { route: to, filter })
  
  if (permission(store, ["EXPERT"]) && to.meta.entityName === "shortOpinions"){
    store.dispatch("socket/SEND_activ", {
      value: true,
      route: to.path
    })
  }

  if (permission(store, ["EXPERT"]) && from.meta.entityName === "shortOpinions"){
    store.dispatch("socket/SEND_activ", {
      value: false,
      route: from.path
    })
  }

  next()
})

router.afterEach(() => {
  store.commit("setTextblocksDrawer", {
    show: false,
    key: "",
    label: "",
    form: "",
    callback: null,
  })
  store.commit("setDialog", "")
  store.commit("setMinimized", "")
  store.commit("setNavigation", true)
})

function checkPermssion(to, from, next) {
  if (to.meta.roles) {
    if (!permission(store, to.meta.roles)) return next("/notfound")
  }

  if(permission(store, ["EXPERT"])){
    const key = to.meta.expert
    if(key){
      const expertTakes = store.getters.getExpertTakes
      if(!expertTakes[key]) return next("/notfound") 
    }
  }

  if(to.matched.every(e => e.meta.redirect)){
    // if (permission(store, ["MEDEXO","COSTBEARER"])){
      return next("/overview");
    // } else {
    //   return next("/shortopinions");
    // }
  }
  next();
}

export default router;
