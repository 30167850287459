import opinionsHeaders from "@/aquana/helper/container/opinionsHeaders";

export default (store) => {
  let result = opinionsHeaders(store)
  
  result = result.map(e => {
    if(e.value?.includes("policyHolder")){
      return {
        ...e,
        value: e.value.replace("policyHolder", "patient")
      }
    }
    return e
  })

  return result;
}
