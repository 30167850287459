import config from "./config"
import account from "./account"
import shortopinions from "./shortopinions"
import secondopinions from "./secondopinions"
import goaes from "./goaes"
import store from "@/_evoli/store"
import shortOpinionsHeaders from "@/aquana/helper/container/shortOpinionsHeaders"
import shortOpinionsLinks from "@/aquana/helper/container/shortOpinionsLinks"
import secondOpinionsHeaders from "@/aquana/helper/container/secondOpinionsHeaders"
import secondOpinionsLinks from "@/aquana/helper/container/secondOpinionsLinks"
import goaesHeaders from "@/aquana/helper/container/goaesHeaders"
import goaesLinks from "@/aquana/helper/container/goaesLinks"

let secondopinionsRoutes = []
if(process.env.VUE_APP_ENV === 'dev'){
  secondopinionsRoutes = secondopinions
}

export default [
  ...shortopinions,
  ...secondopinionsRoutes,
  ...goaes,
  {
    path: "/overview",
    name: "Fallübersicht",
    meta: {
      authorize: true,
      lists: [
        {
          name: "shortOpinionOverviewList",
          entityName: "shortOpinions",
          getHeaders: () => shortOpinionsHeaders(store),
          getLinks: () => shortOpinionsLinks(store, "shortOpinionOverviewList")
        },{
          name: "goaesOverviewList",
          entityName: "goaes",
          getHeaders: () => goaesHeaders(store),
          getLinks: () => goaesLinks(store, "goaesOverviewList")
        },{
          name: "secondOpinionOverviewList",
          entityName: "secondOpinions",
          getHeaders: () => secondOpinionsHeaders(store),
          getLinks: () => secondOpinionsLinks(store, "secondOpinionOverviewList")
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "config" */ "@/aquana/views/overview/OverviewContainer.vue"),
  },
  {
    path: "/config",
    name: "Config",
    meta: {
      authorize: true,
      redirect: true
    },
    children: config,
    component: () =>
      import(/* webpackChunkName: "config" */ "@/aquana/views/config/Config.vue"),
  },
  {
    path: "/account",
    name: "account",
    meta: {
      authorize: true,
      redirect: true
    },
    children: account,
    component: () =>
      import(/* webpackChunkName: "account" */ "@/aquana/views/account/Account.vue"),
  },
  {
    path: "/opiniontypes/:name",
    name: "Gutachten",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "opiniontypes" */ "@/aquana/views/opiniontypes/OpinionTypeForm.vue"
      ),
  },
  {
    path: "/audittrails",
    name: "Änderungen",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "audittrails" */ "@/aquana/views/audittrails/AuditTrailsContainer.vue"
      ),
  },
  {
    path: "/pinboard",
    name: "Pinnwand",
    meta: {
      authorize: true,
      roles: ["MEDEXO"]
    },
    component: () =>
      import(
        /* webpackChunkName: "pinboards" */ "@/aquana/views/pinboards/PinboardContainer.vue"
      )
  },
  {
    path: "/tickets",
    name: "Postfach",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "tickets" */ "@/aquana/views/tickets/TicketContainer.vue"
      ),
  },
  {
    path: "/tickets/:id",
    name: "Postfach",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "tickets" */ "@/aquana/views/tickets/TicketContainer.vue"
      ),
  },
  {
    path: "/vacation",
    name: "Abwesenheitskalender",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "vacation" */ "@/aquana/views/vacation/VacationContainer.vue"
      ),
  },
  {
    path: "/invoice",
    name: "Rechnungen",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "invoice" */ "@/aquana/views/invoice/InvoiceContainer.vue"
      ),
  },
  {
    path: "/evaluation",
    name: "Auswertung",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "evaluation" */ "@/aquana/views/evaluation/EvaluationContainer.vue"
      ),
  },
  {
    path: "/dashboard",
    name: "Dashbaord",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "evaluation" */ "@/aquana/views/dashboard/DashboardContainer.vue"
      ),
  },
  {
    path: "/instructions",
    name: "Anleitungen",
    meta: {
      authorize: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "instructions" */ "@/aquana/views/instructions/InstructionsContainer.vue"
      ),
  },
];
