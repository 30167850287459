import store from "@/_evoli/store"
import secondOpinionsHeaders from "@/aquana/helper/container/secondOpinionsHeaders"
import secondOpinionsLinks from "@/aquana/helper/container/secondOpinionsLinks"

export default [
  {
    path: "secondopinions",
    name: "Zweitmeinungen",
    meta: {
      authorize: true,
      expert: "takesSecondOpinion",
      lists: [
        {
          name: "secondOpinionsList",
          entityName: "secondOpinions",
          getHeaders: () => secondOpinionsHeaders(store),
          getLinks: () => secondOpinionsLinks(store, "secondOpinionsList")
        }
      ]
    },
    component: () =>
      import(
        /* webpackChunkName: "secondopinions" */ "@/aquana/views/secondopinions/SecondOpinionContainer.vue"
      ),
  },
  {
    path: "secondopinions/:id",
    name: "Zweitmeinung",
    meta: {
      authorize: true,
      expert: "takesSecondOpinion",
      entityName: "secondOpinions",
    },
    component: () =>
      import(
        /* webpackChunkName: "secondopinions" */ "@/aquana/views/secondopinions/SecondOpinionForm.vue"
      ),
  },
];
