function endpoint(
  store,
  method,
  route,
  params,
  data,
  skeleton = true,
  json = true,
  responseType = null,
	header = {}
)
  {

  const accessToken = sessionStorage.getItem("accessToken");
  let headers = {
			Authorization:
			accessToken && accessToken !== "undefined" ? "Bearer " + accessToken : "",
			...header
		};

  let body = null;
  if (data) {
    headers["Content-Type"] = "application/json";
    body = { ...data };
    delete body._id;
    body = JSON.stringify(body);
  }

  let url = route.includes("http")
    ? route
    : process.env.VUE_APP_APIURL + route.toLowerCase();
  if (params) url += formatParams(url, params);

  store.commit("addLoading", {
    skeleton,
    method,
    route,
  });

  let requestObject = {
    method,
    body,
    headers,
  };

  if (responseType !== null) requestObject[responseType] = responseType;

  return fetch(url, requestObject)
    .then(async (response) => {
      if(response.status !== 401){
        store.commit("removeLoading", {
          skeleton,
          method,
          route,
        });
      }
      switch (response.status) {
        case 200:
          return json ? await response.json() : await response;
        case 201:
          return {
            location: response.headers.get("Location"),
            response: await response.json()
          };
        case 204:
          return {};
        case 401:
          sessionStorage.removeItem("accessToken");
          store.commit("removeAccessToken");
          return refresh(store)
            .then(() => {
              return endpoint(store, method, route, params, data, skeleton).then((res) => {
                store.commit("removeLoading", {
                  skeleton,
                  method,
                  route,
                })
                return res
              })
            })
            .catch((e) => {
              if (e.status === 409) throw e;
            });
        case 418:
          store.commit("snackbar/open", {
            content: "Es ist ein Fehler aufgetreten. Versuchen Sie es später erneut.",
            color: "error",
            icon: "mdi-alert-circle",
          });
          return response.json().then((error) => {
            throw error;
          });
        case 206:
          return response
        case 424:
          store.commit("snackbar/open", {
            content:
              "Kann nicht gelöscht werden. Verknüpfung zu einem Fall vorhanden.",
            color: "error",
            icon: "mdi-alert-circle",
          });
          return response.json().then((error) => {
            throw error;
          });          
        default:
          return response.json().then((error) => {
            throw error;
          });
      }
    })
    .catch((error) => {
      store.commit("removeLoading", {
        skeleton,
        method,
        route,
      });
      if(error.message === "Failed to fetch"){
        store.commit("setServiceNotAvailable", true);
        store.commit("snackbar/open", {
          content: "Es ist ein Fehler aufgetreten. Die Server sind aktuell nicht erreichbar.",
          color: "error",
          icon: "mdi-alert-circle",
          persistent: true
        });
      }
      throw error;
    });
}

export function refresh(store, retry = true) {
  if(!localStorage.getItem("refreshToken")){
    return
  }
  return fetch(process.env.VUE_APP_APIURL + "/v1/login/refresh", {
    method: "POST",
    body: JSON.stringify({
      service: "account",
      refreshToken: localStorage.getItem("refreshToken"),
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    switch (response.status) {
      case 200:
        if(store.getters.getServiceNotAvailable){
          store.commit("setServiceNotAvailable", false);
          store.commit("snackbar/close");
        }
        return response.json().then((result) => {
          sessionStorage.setItem("accessToken", result.accessToken);
          localStorage.setItem("refreshToken", result.refreshToken);
          store.commit("addAccessToken", result.accessToken);
          store.commit("addRefreshToken", result.refreshToken);
        });
      default:
        localStorage.removeItem("refreshToken");
        store.commit("removeRefreshToken");
        store.dispatch("logout")
        throw response;
    }
  }).catch((error) => {
    if(error.message === "Failed to fetch"){
      store.commit("setServiceNotAvailable", true);
      store.commit("snackbar/open", {
        content: "Es ist ein Fehler aufgetreten. Die Server sind aktuell nicht erreichbar.",
        color: "error",
        icon: "mdi-alert-circle",
        persistent: true
      });
      if(retry){
        setTimeout(() => {
          refresh(store, false)
        }, 3000)
      }
    }
  })
}

function formatParams(url, params) {
  let query = "";
  let startDelimiter = url.includes("?") ? "&" : "?";
  let delimiter = startDelimiter
  Object.keys(params).map(key => {
    if (typeof params[key] !== "undefined" && "" + params[key] !== "" && "" + params[key] !== "null") {
      query += delimiter + key + "=" + params[key]
      delimiter = "&"
    }
  });
  return query;
}

export default endpoint;
