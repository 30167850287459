import permission from "@/_evoli/helper/permission"
import opinionsLinks from "@/aquana/helper/container/opinionsLinks"
import contains from "@/_evoli/helper/contains"

export default (store, list) => {
  if (permission(store, ["ANON"])) {
    return {}
  }

  let links = {
    ...opinionsLinks(store, list),
    patient: {},
  }

  delete links.policyHolder;

  if (
    contains(
      store.state.filterHeaders?.[list],
      [
        "patient.birthDate",
        "patient.gender",
        "patient.insuranceNumber",
      ],
      true
    )
  )
    delete links.patient;

  return links;
};
