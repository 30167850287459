export default {
  getTfaMethod(state) {
    return state.tfaMethod
  },
  getTfaTelephone(state) {
    return state.tfaTelephone
  },
  getRefreshToken(state) {
    return state.refreshToken
  },
  getAccessToken(state) {
    return state.accessToken
  },
  getUserRoles(state) {
    return state.self?.roles || []
  },
  getUserCostBearer(state) {
    return state.self.costBearer
  },
  getExpertTakes(state) {
    const keys = ["takesShortOpinion", "takesSecondOpinion", "takesGoae"]
    let result = {}
    keys.forEach(key => {
      result[key] = state.self[key]
    })
    return result
  }
}
